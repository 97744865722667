import rewritePattern from 'regexpu-core';
import { generateRegexpuOptions } from '@babel/helper-create-regexp-features-plugin/lib/util';

const { RegExp } = window;
try {
  new RegExp('a', 'u');
} catch (err) {
  // @ts-ignore
  window.RegExp = function(pattern, flags) {
    if (flags && flags.includes('u')) {
      return new RegExp(
        rewritePattern(
          pattern,
          flags,
          generateRegexpuOptions({ flags, pattern })
        )
      );
    }
    return new RegExp(pattern, flags);
  };
  // @ts-ignore
  window.RegExp.prototype = RegExp;
}